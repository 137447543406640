import * as React from "react"
import Layout from '../components/Layout'
import Section from '../components/Section'
import { StaticImage } from "gatsby-plugin-image"

const StrangelovePage = () => {
  
	return (
		<Layout pageTitle="A book: Dr. Strangeloves Playground">
			<Section id="book-intro" background="light">
			<div className="flex small-image">
					<div className="image-wrapper">
						<StaticImage
						src="../images/dr-strangeloves-playground-cover.png"
						alt="Cover of the book Dr. Strangelove&rsquo;s Playground by Richard Weeks"
						placeholder="blurred"
						/>
					</div>
					<div className="content-wrapper">
						<h2>Dr. Strangelove&rsquo;s Playground</h2>
						<p><strong>Published: 2019</strong></p>
						<p>This third collection of memoirs begins in the 1940s with an epic Los Angeles snowstorm and concludes with the story of a gardening project unique to the backyards of Oregon. In between stories of a childhood in Southern California and retirement in Oregon, the author describes life with students, friends, and relatives, both famous and obscure.</p>
					</div>
				</div>
			</Section>
	    </Layout>
	)
}

export default StrangelovePage